/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component } from '@angular/core'
import { UnespCoreAuthService } from 'src/libs/unesp-core'

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {
  
  constructor(private unespCoreAuthService: UnespCoreAuthService) {
    this.unespCoreAuthService.logout('Usuário desconectado com sucesso')
  }
  
}
