/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { UnespCoreLayoutModule } from './unesp-core-layout';
import { NgModule } from '@angular/core';
import { UnespCoreAccessDeniedModule } from './unesp-core-accessdenied';
import { UnespCoreFooterModule } from './unesp-core-footer';
// import { UnespCoreHeaderModule } from './unesp-core-header';
import { UnicampCoreHeaderModule } from './unicamp-core-header';
import { UnespCoreHomeModule } from './unesp-core-home';
import { UnespCorePageNotFoundModule } from './unesp-core-pagenotfound';
import { UnespCoreSidenavModule } from './unesp-core-sidenav';
import { UnespCoreSidenavItemModule } from './unesp-core-sidenav-item';
import { UnespCoreHeaderCountdownModule } from './unesp-core-header-countdown';

@NgModule({
  imports: [
    UnespCoreAccessDeniedModule,
    UnespCoreFooterModule,
    // UnespCoreHeaderModule,
    UnicampCoreHeaderModule,
    UnespCoreHeaderCountdownModule,
    UnespCoreHomeModule,
    UnespCorePageNotFoundModule,
    UnespCoreSidenavModule,
    UnespCoreSidenavItemModule,
    UnespCoreLayoutModule
  ],
  exports: [
    UnespCoreAccessDeniedModule,
    UnespCoreFooterModule,
    // UnespCoreHeaderModule,
    UnicampCoreHeaderModule,
    UnespCoreHeaderCountdownModule,
    UnespCoreHomeModule,
    UnespCorePageNotFoundModule,
    UnespCoreSidenavModule,
    UnespCoreSidenavItemModule,
    UnespCoreLayoutModule
  ],
  providers: [],
})
export class UnespCoreComponentsModule { }
