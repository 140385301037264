<mat-divider *ngIf="item.addTopDivider && !item.hidden"></mat-divider>

<div fxLayout="row" fxFlex="1 1 auto" *ngIf="!item.hidden" 
                    [ngStyle]="{'pointer-events': item.disabled ?'none':'auto', 'opacity': item.disabled? '0.15' : '1'}"
                    class="noselect">
<mat-list-item class="unesp-menu-mat-list-item" fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center stretch"
               [ngClass]="{ smallSize: item.smallSize }">
  <a (click)="onItemSelected(item)"
    [ngClass]="{ active: item.route ? router.isActive(item.route, true) : false, expanded: expanded}"
    fxLayout="row" fxFlexFill fxLayoutAlign="space-between center">
    <div fxFlex="0 0 auto" [ngStyle]="{ 'width': depth * 8 + 'px' }"></div>

    <mat-icon *ngIf="item.iconName" class="unesp-menu-item-icon" fxFlex="0 0 auto">
      {{item.iconName}}
    </mat-icon>

    <div class="unesp-menu-item" [ngStyle]="(item.children && item.children.length>0)? { 'font-weight': '500' } : {}" fxFlex="1 1 auto">
      {{ item.displayName }}
    </div>

    <div *ngIf="item.children && item.children.length && !item.disabled" fxFlex="0 0 auto">
      <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
        expand_more
      </mat-icon>
    </div>
  </a>
</mat-list-item>
</div>

<div *ngIf="expanded && !item.hidden && !item.disabled" fxFlex="1 1 auto" fxLayout="column">
  <unesp-core-sidenav-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1" fxFlex="0 0 auto" fxLayout="column">
  </unesp-core-sidenav-item>
</div>

<mat-divider *ngIf="item.addBottomDivider && !item.hidden" fxFlex="0 0 auto"></mat-divider>