<div fxLayout="column" fxLayoutGap="20px">
  <h1>Consulta Andamento</h1>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>Unidade</mat-label>
    <mat-chip-grid #unidadesChipList>
      <mat-chip-option
        *ngIf="showTodasUnidadesMC && unidadesSelecionadas.length === 0"
        (removed)="removeChip(todasUnidades)">
        {{ todasUnidades.nome }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
      <mat-chip-option *ngFor="let unidade of unidadesSelecionadas" (removed)="removeChip(unidade)">
        {{ unidade.nome }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-option>
      <input
        #unidadeInput
        placeholder="Unidade"
        [matChipInputFor]="unidadesChipList"
        [matChipInputAddOnBlur]="true"
        (focus)="focusChipIput()"
        (matChipInputTokenEnd)="blurChipIput($event)"
        [formControl]="unidadeFC"
        [matAutocomplete]="autoUnidade" />
    </mat-chip-grid>
    <mat-autocomplete autoActiveFirstOption #autoUnidade="matAutocomplete" [displayWith]="nomeUnidade">
      <mat-option *ngFor="let opt of unidadesFiltradas | async" [value]="opt">
        {{ opt.nome }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <div fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Modalidade</mat-label>
        <mat-select [formControl]="tipoConcursoFC">
          <mat-option value="TODAS">Todas as modalidades</mat-option>
          <mat-option *ngFor="let enum of tipoConcurso | keyvalue" [value]="enum.key">
            {{ enum.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Etapa</mat-label>
        <mat-select [formControl]="tipoAndamentoFC">
          <mat-option value="TODAS">Todas as etapas</mat-option>
          <mat-option *ngFor="let enum of tipoAndamento | keyvalue" [value]="enum.key">
            {{ enum.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Data de início das inscrições (maior ou igual)</mat-label>
        <input matInput readonly [matDatepicker]="pickerData" [formControl]="dataInscricaoFC" [max]="dataMaxima" />
        <mat-icon class="danger" matPrefix (click)="clearDate()">close</mat-icon>
        <mat-datepicker-toggle matSuffix [for]="pickerData"></mat-datepicker-toggle>
        <mat-datepicker #pickerData></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end">
    <button mat-raised-button color="primary" (click)="consultar()">
      <mat-icon>check_circle_outline</mat-icon> Consultar
    </button>
    <button mat-raised-button color="primary" (click)="exportar()"><mat-icon>save_alt</mat-icon> Exportar</button>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="inscricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Inscrição</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inicio | date : 'dd/MM/yyyy' }}<br />{{ row.fim | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="campus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Unidade</th>
        <td mat-cell *matCellDef="let row">{{ row.campus }}</td>
      </ng-container>
      <ng-container matColumnDef="titulo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
        <td mat-cell *matCellDef="let row">{{ row.titulo }}</td>
      </ng-container>
      <ng-container matColumnDef="processo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Processo</th>
        <td mat-cell *matCellDef="let row">{{ row.processo }}</td>
      </ng-container>
      <ng-container matColumnDef="contad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="numeroContad">CONTAD</th>
        <td mat-cell *matCellDef="let row">{{ row.numeroContad }}</td>
      </ng-container>
      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let row">{{ tipoConcursoTexto(row.tipo) }}</td>
      </ng-container>
      <ng-container matColumnDef="andamento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Edital atual</th>
        <td mat-cell *matCellDef="let row">{{ row.andamento }}<br />{{ row.andamentoData | date : 'dd/MM/yyyy' }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span>Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
