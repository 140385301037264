<mat-card>
  <div *ngIf="inscricaoReducao?.reducao" class="reducao-solicitada-msg">
    <span *ngIf="!inscricaoReducao?.reducaoData">Redução Solicitada e Aguardando Análise</span>
    <span *ngIf="inscricaoReducao?.reducaoData && !inscricaoReducao?.reducaoAprovado">Redução Não aprovada</span>
    <span *ngIf="inscricaoReducao?.reducaoData && inscricaoReducao?.reducaoAprovado">Redução Aprovada</span>
    <span *ngIf="inscricaoReducao?.reducaoData && !inscricaoReducao?.reducaoConcluida"> (Não concluída)</span>
  </div>
  <mat-card-content>
    <form [formGroup]="form" fxLayout="column" fxLayoutGap="20px">
      <fieldset fxFlex="100%">
        <legend>Redução</legend>

        <div fxLayout="column" fxLayoutGap="5px">
          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Unidade</mat-label>
              <input matInput [value]="inscricaoReducao?.concursoCampus" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concurso</mat-label>
              <input matInput [value]="inscricaoReducao?.concursoTitulo" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>CPF</mat-label>
              <input matInput [value]="inscricaoReducao?.candidatoCpf" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Nome</mat-label>
              <input matInput [value]="inscricaoReducao?.nome" [disabled]="true" />
            </mat-form-field>
          </div>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Valor</mat-label>
              <input matInput [value]="inscricaoReducao?.valor" name="valor" [disabled]="true" />
              <mat-hint>&nbsp;</mat-hint>
            </mat-form-field>

            <app-arquivo-upload
              [autoUpload]="true"
              [fc]="anexoEstudanteFormControl"
              accept="application/pdf"
              uploadPath="{{ 'inscricao/' + inscricaoReducao?.id + '/comprovanteestudante' }}"
              [usarArquivoViewer]="true"
              label="Comprovante Estudante"></app-arquivo-upload>

            <app-arquivo-upload
              [autoUpload]="true"
              [fc]="anexoRendaFormControl"
              accept="application/pdf"
              uploadPath="{{ 'inscricao/' + inscricaoReducao?.id + '/comprovanterenda' }}"
              [usarArquivoViewer]="true"
              label="Comprovante de Renda"></app-arquivo-upload>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout.gt-md="row" fxLayoutGap="20px" fxFill>
            <mat-form-field appearance="outline">
              <mat-label>Data</mat-label>
              <input
                matInput
                [value]="inscricaoReducao?.reducaoData | date : 'dd/MM/yyyy HH:mm:ss'"
                name="reducaoData"
                [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Usuário</mat-label>
              <input matInput [value]="inscricaoReducao?.reducaoUsuario" name="reducaoUsuario" [disabled]="true" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Concluído</mat-label>
              <input
                matInput
                [value]="inscricaoReducao?.reducaoConcluida ? 'Sim' : 'Não'"
                name="reducaoConcluida"
                [disabled]="true" />
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label>Aprovado</mat-label>
            <mat-select name="reducaoAprovado" formControlName="reducaoAprovado">
              <mat-option value="true">Sim</mat-option>
              <mat-option value="false">Não</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Motivo</mat-label>
            <input matInput formControlName="reducaoMotivo" name="reducaoMotivo" />
          </mat-form-field>
        </div>
      </fieldset>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div
      fxLayoutAlign.gt-md="space-between center"
      fxLayoutAlign.lt-md="center stretch"
      fxLayout.lt-md="column"
      fxLayoutGap="20px">
      <ng-container *ngIf="isPermitirAlteracao; then permitirAlteracaoBtn; else atualizarConcluirBtns"></ng-container>
      <ng-template #permitirAlteracaoBtn>
        <button
          mat-raised-button
          color="primary"
          (click)="permitirAlteracao()"
          [disabled]="!inscricaoReducao?.reducaoConcluida">
          <mat-icon>edit</mat-icon> Reverter
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>arrow_back</mat-icon> Voltar</button>
      </ng-template>
      <ng-template #atualizarConcluirBtns>
        <button mat-raised-button color="primary" (click)="gravar()" [disabled]="inscricaoReducao?.reducaoConcluida">
          <mat-icon>edit</mat-icon> Atualizar
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="concluir()"
          [disabled]="!isAtualizado || inscricaoReducao?.reducaoData === null || inscricaoReducao?.reducaoConcluida">
          <mat-icon>done</mat-icon> Concluir e notificar
        </button>
        <button mat-stroked-button color="warn" routerLink="../.."><mat-icon>close</mat-icon> Cancelar</button>
      </ng-template>
    </div>
  </mat-card-actions>
</mat-card>
