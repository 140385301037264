/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { NgModule } from '@angular/core';
import { UnespCoreApiErrorHandlerModule } from './unesp-core-api-error-handler';
import { UnespCoreAuthGuardModule } from './unesp-core-auth-guard/unesp-core-auth-guard.module';
import { UnespCoreAuthModule } from './unesp-core-auth/unesp-core-auth.module';
import { UnespCoreGenericServiceModule } from './unesp-core-generic-service';
import { UnespCoreLoaderModule } from './unesp-core-loader';
import { UnespCoreMenuNavigationModule } from './unesp-core-menu-navigation';
import { UnespCoreMessageModule } from './unesp-core-message';
import { UnespCoreUserModule } from './unesp-core-user';

@NgModule({
  declarations: [],
  imports: [
    UnespCoreApiErrorHandlerModule,
    UnespCoreAuthModule,
    UnespCoreAuthGuardModule,
    UnespCoreUserModule,
    UnespCoreGenericServiceModule,
    UnespCoreLoaderModule,
    UnespCoreMenuNavigationModule,
    UnespCoreMessageModule
  ],
  exports: [
    UnespCoreApiErrorHandlerModule,
    UnespCoreAuthModule,
    UnespCoreAuthGuardModule,
    UnespCoreUserModule,
    UnespCoreGenericServiceModule,
    UnespCoreLoaderModule,
    UnespCoreMenuNavigationModule,
    UnespCoreMessageModule
  ],
  providers: [],
})
export class UnespCoreServicesModule {}
