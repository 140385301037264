/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
registerLocaleData(localePt, 'pt')
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@ngbracket/ngx-layout'
import { MatDialogModule } from '@angular/material/dialog'
import { ServiceWorkerModule } from '@angular/service-worker'
import { UnespCoreHomeModule, UnespCoreModule, UnespCoreUserService } from 'src/libs/unesp-core'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { environment } from 'src/environments/environment'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { getMenu } from './menu'
import { InterceptorsModule } from './interceptors/interceptors.module'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatRadioModule } from '@angular/material/radio'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatCardModule } from '@angular/material/card'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator'
import { MatSelectModule } from '@angular/material/select'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDividerModule } from '@angular/material/divider'
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from '@angular/material/core'
import { PtBrMatPaginatorIntl } from './pt-br-paginator-intl'
import { MatSortModule } from '@angular/material/sort'
import { MatChipsModule } from '@angular/material/chips'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatTabsModule } from '@angular/material/tabs'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatListModule } from '@angular/material/list'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatMenuModule } from '@angular/material/menu'

import { HomeComponent } from './modules/home/home.component'
import { ArquivoUploadComponent } from './modules/arquivo-upload/arquivo-upload.component'
import { ListaConcursoComponent } from './modules/concurso/lista-concurso/lista-concurso.component'
import { CadastroConcursoComponent } from './modules/concurso/cadastro-concurso/cadastro-concurso.component'
import { CadastroAndamentoComponent } from './modules/concurso/cadastro-andamento/cadastro-andamento.component'
import { ListaUsuarioExternoComponent } from './modules/usuario-externo/lista-usuario-externo/lista-usuario-externo.component'
import { CadastroUsuarioExternoComponent } from './modules/usuario-externo/cadastro-usuario-externo/cadastro-usuario-externo.component'
import { ListaInscricaoComponent } from './modules/inscricao/lista-inscricao/lista-inscricao.component'
import { InscricaoFichaComponent } from './modules/inscricao/inscricao-ficha/inscricao-ficha.component'
import { InscricaoPagamentoComponent } from './modules/inscricao/inscricao-pagamento/inscricao-pagamento.component'
import { InscricaoPagamentoDevolucaoComponent } from './modules/inscricao/inscricao-pagamento-devolucao/inscricao-pagamento-devolucao.component'
import { InscricaoReducaoComponent } from './modules/inscricao/inscricao-reducao/inscricao-reducao.component'
import { InscricaoSituacaoComponent } from './modules/inscricao/inscricao-situacao/inscricao-situacao.component'
import { ListaUsuarioCandidatoComponent } from './modules/usuario-candidato/lista-usuario-candidato/lista-usuario-candidato.component'
import { UsuarioCandidatoEmailComponent } from './modules/usuario-candidato/usuario-candidato-email/usuario-candidato-email.component'
import { UsuarioCandidatoSenhaComponent } from './modules/usuario-candidato/usuario-candidato-senha/usuario-candidato-senha.component'
import { ListaRecursoComponent } from './modules/recurso/lista-recurso/lista-recurso.component'
import { RecursoAnaliseComponent } from './modules/recurso/recurso-analise/recurso-analise.component'
import { ListaConfiguracaoComponent } from './modules/configuracao/lista-configuracao/lista-configuracao.component'
import { ConfiguracaoEditarComponent } from './modules/configuracao/configuracao-editar/configuracao-editar.component'
import { PermitirAlteracaoComponent } from './modules/permitir-alteracao/permitir-alteracao.component'
import { AndamentoFormComponent } from './modules/andamento/andamento-form/andamento-form.component'
import { PermitirAlterarAndamentoComponent } from './modules/permitir-alteracao/permitir-alterar-andamento/permitir-alterar-andamento.component'
import { ConsultaAndamentoComponent } from './modules/consulta-andamento/consulta-andamento.component'
import { PendingChangesGuard } from './utils/pending-changes'
import { ListaFormularioComplementarComponent } from './modules/formulario-complementar/lista-formulario-complementar/lista-formulario-complementar.component'
import { PreviaFormularioComplementarComponent } from './modules/formulario-complementar/previa-formulario-complementar/previa-formulario-complementar.component'
import {
  FormularioComplementarInputGrupoComponent,
  FormularioComplementarInputCheckboxComponent,
  FormularioComplementarInputFileComponent,
  FormularioComplementarInputTextComponent,
} from './modules/formulario-complementar/formulario-complementar-input/formulario-complementar-input.component'
import { CadastroFormularioComplementarComponent } from './modules/formulario-complementar/cadastro-formulario-complementar/cadastro-formulario-complementar.component'
import { ListaPerfilComponent } from './modules/perfis/lista-perfil/lista-perfil.component'
import { PerfilPorUsuarioComponent } from './modules/perfis/perfil-por-usuario/perfil-por-usuario.component'
import { AdicionarPerfilComponent } from './modules/perfis/adicionar-perfil/adicionar-perfil.component'
import {
  InformacaoComplementarGrupoComponent,
  InformacaoComplementarCheckboxComponent,
  InformacaoComplementarFileComponent,
  InformacaoComplementarTextComponent,
} from './modules/inscricao/inscricao-ficha/informacao-complementar/informacao-complementar.component'
import { AjudaSuporteComponent } from './modules/ajuda/suporte/ajuda-suporte.component'
import { InscricaoAnexosComponent } from './modules/inscricao/inscricao-anexos/inscricao-anexos.component'
import { ArquivoViewerDialogComponent } from './modules/arquivo-viewer-dialog/arquivo-viewer-dialog.component'
import { ConsultaGerencialComponent } from './modules/consulta-gerencial/consulta-gerencial.component'
import { ConsultaLogComponent } from './modules/consulta-log/consulta-log.component'
import { LogDetalhesComponent } from './modules/consulta-log/log-detalhes/log-detalhes.component'
import { PagamentoComponent } from './modules/listagens/pagamento/pagamento.component'
import { ReducaoTaxaComponent } from './modules/listagens/reducao-taxa/reducao-taxa.component'
import { SituacaoInscricaoComponent } from './modules/listagens/situacao-inscricao/situacao-inscricao.component'
import { ClassificacaoInscricaoComponent } from './modules/listagens/classificacao-inscricao/classificacao-inscricao.component'
import { ListaInscricoesDocumentoComponent } from './modules/listagens/lista-inscricoes-documento/lista-inscricoes-documento.component'
import { InscricaoDocumentosComponent } from './modules/inscricao/inscricao-documentos/inscricao-documentos.component'
import { ProvasGerenciaComponent } from './modules/concurso/provas/provas-gerencia/provas-gerencia.component'
import { ProvaCriteriosComponent } from './modules/concurso/provas/prova-criterios/prova-criterios.component'
import { ListarNotasCandidatosComponent } from './modules/concurso/provas/prova-notas/listar-notas-candidatos/listar-notas-candidatos.component'
import { DigitarNotasCandidatoComponent } from './modules/concurso/provas/prova-notas/digitar-notas-candidato/digitar-notas-candidato.component'
import { ManualComponent } from './modules/manual/manual/manual.component'
import { CentralAcessoHomeComponent } from './modules/central-acesso/home/central-acesso-home.component'
import { InscricaoSituacaoPipe } from './pipes/inscricao-situacao.pipe'
import { NumberRoundPipe } from './pipes/number-round.pipe'
import { SalvarNotasDialogComponent } from './modules/concurso/provas/prova-notas/digitar-notas-candidato/salvar-notas-dialog/salvar-notas-dialog.component'
import { TipoRecursoListarComponent } from './modules/tipo-recurso/listar/tipo-recurso-listar.component'
import { TipoRecursoEditarComponent } from './modules/tipo-recurso/editar/tipo-recurso-editar.component'
import { TipoRecursoIncluirComponent } from './modules/tipo-recurso/incluir/tipo-recurso-incluir/tipo-recurso-incluir.component'
import { ConfirmDialogComponent } from './modules/confirm-dialog/confirm-dialog.component'
import { UsuarioCandidatoVincularContasComponent } from './modules/usuario-candidato/usuario-candidato-vincular-contas/usuario-candidato-vincular-contas.component'
import { StatusDocumentacaoComponent } from './modules/concurso/status-documentacao/status-documentacao.component'
import { AuthComponent } from './modules/auth/auth.component'
import { LogoutComponent } from './modules/logout/logout.component'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AuthComponent,
    LogoutComponent,
    ArquivoUploadComponent,
    ListaConcursoComponent,
    CadastroConcursoComponent,
    CadastroAndamentoComponent,
    ListaUsuarioExternoComponent,
    CadastroUsuarioExternoComponent,
    ListaInscricaoComponent,
    InscricaoFichaComponent,
    InscricaoPagamentoComponent,
    InscricaoPagamentoDevolucaoComponent,
    InscricaoReducaoComponent,
    InscricaoSituacaoComponent,
    ListaUsuarioCandidatoComponent,
    UsuarioCandidatoEmailComponent,
    UsuarioCandidatoSenhaComponent,
    ListaRecursoComponent,
    RecursoAnaliseComponent,
    ListaConfiguracaoComponent,
    ConfiguracaoEditarComponent,
    PermitirAlteracaoComponent,
    AndamentoFormComponent,
    PermitirAlterarAndamentoComponent,
    ConsultaAndamentoComponent,
    ListaFormularioComplementarComponent,
    PreviaFormularioComplementarComponent,
    FormularioComplementarInputGrupoComponent,
    FormularioComplementarInputCheckboxComponent,
    FormularioComplementarInputFileComponent,
    FormularioComplementarInputTextComponent,
    CadastroFormularioComplementarComponent,
    ListaPerfilComponent,
    PerfilPorUsuarioComponent,
    AdicionarPerfilComponent,
    InformacaoComplementarGrupoComponent,
    InformacaoComplementarCheckboxComponent,
    InformacaoComplementarFileComponent,
    InformacaoComplementarTextComponent,
    AjudaSuporteComponent,
    InscricaoAnexosComponent,
    ArquivoViewerDialogComponent,
    ConsultaGerencialComponent,
    ConsultaLogComponent,
    LogDetalhesComponent,
    PagamentoComponent,
    ReducaoTaxaComponent,
    SituacaoInscricaoComponent,
    ClassificacaoInscricaoComponent,
    ListaInscricoesDocumentoComponent,
    InscricaoDocumentosComponent,
    ProvasGerenciaComponent,
    ProvaCriteriosComponent,
    ListarNotasCandidatosComponent,
    DigitarNotasCandidatoComponent,
    ConfirmDialogComponent,
    SalvarNotasDialogComponent,
    ManualComponent,
    CentralAcessoHomeComponent,
    InscricaoSituacaoPipe,
    NumberRoundPipe,
    TipoRecursoListarComponent,
    TipoRecursoEditarComponent,
    TipoRecursoIncluirComponent,
    UsuarioCandidatoVincularContasComponent,
    StatusDocumentacaoComponent,
  ],
  imports: [
    AppRoutingModule,
    UnespCoreModule,
    InterceptorsModule,
    UnespCoreHomeModule,
    MatDialogModule,
    FlexLayoutModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatListModule,
    DragDropModule,
    MatMenuModule,
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    // { provide: 'menu', useValue: menu },
    {
      provide: 'menu',
      deps: [UnespCoreUserService],
      useFactory: (unespCoreUserService: UnespCoreUserService) => {
        return getMenu(unespCoreUserService.getUser() === null, unespCoreUserService.getUser()?.roles ?? [])
      }
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: MatPaginatorIntl, useValue: PtBrMatPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    PendingChangesGuard,
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
