/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { UnespCoreMenuNavigationService } from '../../services/unesp-core-menu-navigation/unesp-core-menu-navigation.service';
import { UnespCoreMenuItem } from '../../interfaces/unesp-core-menu-item';

/**
 * @description
 *
 * Este componente representa os itens de menu da aplicação.
 *
 */
@Component({
  selector: 'unesp-core-sidenav-item',
  templateUrl: './unesp-core-sidenav-item.component.html',
  styleUrls: ['./unesp-core-sidenav-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class UnespCoreSidenavItemComponent implements OnInit {
  expanded!: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded!: boolean;
  @Input() item!: UnespCoreMenuItem;
  @Input() depth!: number;

  constructor(
    public unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    public router: Router
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit(): void {
    this.unespCoreMenuNavigationService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
    });
  }

  onItemSelected(item: UnespCoreMenuItem): void {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      this.unespCoreMenuNavigationService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
