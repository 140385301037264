/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import {ROLE_WILDCARD_ONLYUSERS, ROLE_WILDCARD_PUBLIC,} from '../../interfaces';
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UnespCoreEnvironment} from '../../interfaces';
import {UnespCoreRolesRoute} from '../../interfaces';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {UnespCoreMenuNavigationService} from '../unesp-core-menu-navigation';
import {UnespCoreMessageService} from '../unesp-core-message';
import {UnespCoreAuthorizationCode} from '../../interfaces';
import {UnespCoreUsuario} from '../../interfaces';
import {UnespCoreUserService} from '../unesp-core-user';

/**
 * @description
 *
 * Serviço responsável por se comunicar com a Central de Acessos e definir usuário para a aplicação.
 *
 * - Efetua autorização na central de acessos
 * - Aciona serviço UnespCoreUserService para armazenar o usuário autorizado
 * - Realiza verificações de permissões baseadas em roles
 */
@Injectable({
  providedIn: 'root',
})
export class UnespCoreAuthService {
  public clientId;
  public redirectUri;
  public authServer;
  public resourceServer;
  public tokenDateKey;

  public refreshTokenExpirationInSeconds?: number;
  private _aquiredTokenTimeLeft$ = new BehaviorSubject<number | null>(null);
  get aquiredTokenTimeLeft$(): Observable<number | null> {
    return this._aquiredTokenTimeLeft$;
  }

  constructor(
    protected http: HttpClient,
    protected unespCoreUserService: UnespCoreUserService,
    protected router: Router,
    protected unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    protected unespCoreMessageService: UnespCoreMessageService,
    @Inject('environment') protected environment: UnespCoreEnvironment
  ) {
    this.clientId = environment.clientId;
    this.redirectUri = environment.baseUrlRedirect;
    this.authServer = environment.oauthAuthorize;
    this.resourceServer = environment.baseUrlApi;
    this.refreshTokenExpirationInSeconds = environment.refreshTokenExpirationInSeconds;
    this.tokenDateKey = this.unespCoreUserService.getLocalStoragePrefix() + '_tokendate';
  }

  /**
   * Realiza autorização na central de acesso.
   *
   * @param {string} code Authorization Code obtido na central de acessos.
   */
  retrieveToken(code: string): void {
    const authorizationCode: UnespCoreAuthorizationCode = {
      code,
    };

    this.http
      .post<UnespCoreUsuario>(`${this.resourceServer}/auth`, authorizationCode)
      .subscribe(
        (usuario) => {
          // set roles
          const authorities = usuario?.authorities;

          const roles = Object.entries(this.environment.roles).filter(
            (role) => {
              return authorities?.indexOf(role[1].name) !== -1;
            }
          );

          usuario.roles = roles.map((role) => role[0]);
          usuario.roles.push(ROLE_WILDCARD_PUBLIC);
          usuario.roles.push(ROLE_WILDCARD_ONLYUSERS);

          this.renewAquiredTokenTimeleft();

          this.unespCoreMessageService.showMessageSuccess(
            'Usuário autenticado com sucesso'
          );
          this.unespCoreUserService.setUser(usuario);
          this.unespCoreMenuNavigationService.refreshMenu();
        },
        (err) => {
          this.unespCoreMessageService.showMessageError(
            'Ocorreu um erro durante a autenticação: ' + err
          );
        }
      );
  }

  /**
   * Realiza logout do usuário na aplicação. O backend é acionado para remoção dos cookies de sessão do qual
   * o javascript não tem acesso.
   *
   * @param {string} mensagem Mensagem a ser exibida ao usuário.
   * @param {boolean} error Indica se a mensagem representa um erro ou não.
   */
  logout(mensagem: string, error: boolean = false): void {
    this.http.get(`${this.resourceServer}/auth/logout`).subscribe(
      () => {
        if (mensagem) {
          if (!error) {
            this.unespCoreMessageService.showMessageSuccess(mensagem);
          } else {
            this.unespCoreMessageService.showMessageError(mensagem);
          }
        }
      },
      (err) =>
        this.unespCoreMessageService.showMessageError(
          'Ocorreu um problema para efetuar logout no backend'
        ),
      () => {
        this.eraseAquiredTokenTimeleft();
        this.unespCoreUserService.removeUser();
        this.router.navigate(['']);
        this.unespCoreMenuNavigationService.refreshMenu();
      }
    );
  }

  /**
   * Verifica se o usuário tem alguma das roles presentes na lista de roles autorizadas enviadas no parâmetro rolesRoute
   *
   * @param { UnespCoreRolesRoute } rolesRoute Lista de roles autorizadas a ser comparada com as roles do usuário
   */
  checkAuthorizedRole(rolesRoute: UnespCoreRolesRoute): boolean {
    const usuario = this.unespCoreUserService.getUser();

    // Role public, route não precisa de usuário
    if (rolesRoute.allowedRoles.includes(ROLE_WILDCARD_PUBLIC)) return true;

    // Só avança se houver usuário logado
    if (!usuario || !usuario.authorities) {
      return false;
    }

    // Role Only Users, route precisa de usuário (mesmo sem roles)
    if (rolesRoute.allowedRoles.includes(ROLE_WILDCARD_ONLYUSERS)) return true;

    // Verifica se o usuário tem alguma das roles autorizadas
    return usuario.authorities.some((authority) =>
      rolesRoute.allowedRoles.includes(authority)
    );
  }

  /**
   * Verifica se o usuário tem alguma das roles presentes na lista de roles autorizadas enviadas no parâmetro requiredRoles
   *
   * @param { string[] } requiredRoles Lista de roles autorizadas a ser comparada com as roles do usuário
   */
  hasPermissionBasedRoles(requiredRoles: string[]): boolean {
    const usuario = this.unespCoreUserService.getUser();

    if (usuario) {
      return usuario.roles.some((role) => requiredRoles.indexOf(role) !== -1);
    }

    return false;
  }

  /**
   * Aciona API para refresh token
   */
  refreshToken(): Observable<any> {
    return this.http.get(`${this.resourceServer}/auth/refresh-token`);
  }

  /**
   * Gera a URL de Login
   */
  getLoginUrl(): string {
    return `${this.authServer}?response_type=code&scope=openid&client_id=${this.clientId}&redirect_uri=${this.redirectUri}`;
  }

  renewAquiredTokenTimeleft(): void {
    localStorage.setItem(this.tokenDateKey, Math.round(Date.now()).toString());
    this._aquiredTokenTimeLeft$.next(this.refreshTokenExpirationInSeconds || 0);
  }

  eraseAquiredTokenTimeleft(): void {
    this._aquiredTokenTimeLeft$.next(0);
  }


}
