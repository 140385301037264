import { UnespCoreHeaderCountdownModule } from '../unesp-core-header-countdown';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { UnicampCoreHeaderComponent } from './unicamp-core-header.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from "@ngbracket/ngx-layout";

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    UnespCoreHeaderCountdownModule
  ],
  declarations: [
    UnicampCoreHeaderComponent
  ],
  exports: [
    UnicampCoreHeaderComponent
  ]
})
export class UnicampCoreHeaderModule { }
