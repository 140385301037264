import { Component, Input, AfterViewInit } from '@angular/core';
import { UnespCoreAuthService } from '../../services/unesp-core-auth/unesp-core-auth.service';
import { UnespCoreUsuario } from '../../interfaces/unesp-core-usuario';
import { UnespCoreMenuNavigationService } from '../../services/unesp-core-menu-navigation/unesp-core-menu-navigation.service';
import { UnespCoreUserService } from '../../services/unesp-core-user/unesp-core-user.service';
import { Observable } from 'rxjs';

/**
 * @description
 *
 * Este componente representa o cabeçalho da aplicação, disponibilizando o menu, login e logout.
 *
 */
@Component({
  selector: 'unicamp-core-header',
  templateUrl: './unicamp-core-header.component.html',
  styleUrls: ['./unicamp-core-header.component.css'],
})
export class UnicampCoreHeaderComponent implements AfterViewInit {
  /**
   * @description
   *
   * Nome completo da aplicação a ser exibido em telas maiores.
   *
   */
  @Input() fullTitle!: string;

  /**
   * @description
   *
   * Nome abreviado da aplicação a ser exibido em telas menores.
   *
   */
  @Input() shortTitle!: string;

  /**
   * @description
   *
   * Exibe username no cabeçalho ao lado do botão logout.
   *
   */
  @Input() usernameOnHeader: boolean = true;

  /**
   * @description
   *
   * Caminho do logotipo que será exibido
   *
   */
  @Input() logo: string = 'assets/img/unicamp-logo.png';

  @Input() logoDetic: string ='assets/img/detic-logo.png';


  /**
   * @description
   *
   * Caminho do logotipo customizado que será exibido
   *
   */
   @Input() logoCustom: string | undefined;

  /**
   * @description
   *
   * Define se o botão do menu irá aparecer a todo momento.
   * Mesmo sem um usuário logado.
   * 
   */
   @Input() publicMenu: boolean = true;

   /**
    * @description
    * 
    * Define se adicionará e exibirá as funcionalidades do componente
    * de countdown timer no cabeçalho.
    */
    @Input() showCountdownTimer: boolean = true;

   /**
    * @description
    * 
    * Define se exibirá o botão de login no header
    */
    @Input() loginOnHeader: boolean = false;

   /**
    * @description
    * 
    * Define se exibirá o botão de logout no header
    */
    @Input() logoutOnHeader: boolean = true;

    constructor(
    public unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    private unespCoreAuthService: UnespCoreAuthService,
    private unespCoreUserService: UnespCoreUserService
  ) {}

  ngAfterViewInit(): void {
    this.unespCoreMenuNavigationService.publicMenu = this.publicMenu;
    this.unespCoreMenuNavigationService.refreshMenu();
  }

  logout(): void {
    this.unespCoreAuthService.logout('Usuário desconectado com sucesso');
    this.unespCoreMenuNavigationService.appDrawer?.close();
  }

  login(): void {
    window.location.href = this.unespCoreAuthService.getLoginUrl();
  }

  get user$(): Observable<UnespCoreUsuario | null> {
    return this.unespCoreUserService.user$;
  }
}
