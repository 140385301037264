/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UnespCoreMessage, UnespCoreMessageType } from '../../interfaces';

/**
 * @description
 *
 * Serviço responsável por exibir mensagens de sucesso/erro/informação para o usuário utilizando
 * o componente SnackBar do Angular Material.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UnespCoreMessageService {

  constructor(private snackBar: MatSnackBar) { }

  /**
   *
   * Exibe a mensagem de sucesso especificada para o usuário
   *
   */
  public showMessageSuccess(msg: string, action: string = 'Fechar', duration?: number): void {
    this.showMessage(msg, action, 'success-snackbar', duration);
  }

  /**
   *
   * Exibe a mensagem de erro especificada para o usuário
   *
   */
  public showMessageWarning(msg: string, action: string = 'Fechar', duration?: number): void {
    this.showMessage(msg, action, 'warning-snackbar', duration);
  }

  /**
   *
   * Exibe a mensagem de erro especificada para o usuário
   *
   */
  public showMessageError(msg: string, action: string = 'Fechar', duration?: number): void {
    this.showMessage(msg, action, 'error-snackbar', duration);
  }

  /**
   *
   * Exibe múltiplas mensagens ao usuário agrupados por tipo e quantidade de mensagens por notificação.
   * O componente Snackbar somente exibe uma notificação por vez conforme especificação do Material.
   * Neste método apenas uma notificação será exibida por vez, porém cada notificação pode ter várias mensagens
   * de acordo com o que foi informado no parâmetro 'numberOfMessagesPerNotification' (Default 3).
   * Um delay de 500 milisegundos é utilizado entre as notificações (transição).
   * A notificação será exibida por 3 segundos por padrão, mas pode ser customizado pelo parâmetro 'duration'
   * Serão exibidos em ordem: Error, Warn, Info, Success (Ordem do ENUM)
   *
   */
  public showMessagesGroupByType(msgs: UnespCoreMessage[], action: string = 'Fechar', numberOfMessagesPerNotification: number = 3, duration: number = 3000): void {

    // timeout between messages (transition)
    const timeout: number = 500;

    // index that control timeout between messages
    let index = 0;

    Object.values(UnespCoreMessageType).forEach(unespCoreMessageType => {
      const msgsGroupedByType = msgs.filter(message => message.type === unespCoreMessageType);

      let chunked: UnespCoreMessage[][] = [];

      for (let i = 0; i < msgsGroupedByType.length; i += numberOfMessagesPerNotification) {
        chunked.push(msgsGroupedByType.slice(i, i + numberOfMessagesPerNotification))
      }

      chunked.forEach(mensagens => {
        let message = '';

        mensagens.forEach(currentValue => {
          message += currentValue.message + '\n\n';
        });

        setTimeout(() => {
          this.showMessage(message, action, mensagens[0].type, duration);

        }, index++ * (duration + timeout)); // timeout between messages
      });

    });

  }

  /**
   *
   * Exibe a mensagem informativa especificada para o usuário
   *
   */
  public showMessageInfo(msg: string, action: string = 'Fechar', duration?: number): void {
    this.showMessage(msg, action, 'info-snackbar', duration);
  }

  /**
   *
   * Recebe a mensagem e monta o SnackBar para exibição ao usuário
   *
   */
  private showMessage(msg: string, action: string = 'Fechar', panelClass: string, duration: number = 3000): void {
    this.snackBar.open(msg, action, {
      duration: duration,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass
    });
  }

}
