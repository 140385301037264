/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { map, Observable, of, startWith, tap } from 'rxjs'
import {
  getAcaoTipoRegistro,
  getAcaoTipoPai,
  geraTextoAcao,
  LogRegistros,
  LogAcoes,
  LogRegistrosMap,
} from 'src/app/enums/tipo-acao-log'
import { DatePipe } from '@angular/common'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ConsultaLogService } from 'src/app/services/consulta-log.service'
import { LogEntry } from 'src/app/models/log-entry'
import { MatDialog } from '@angular/material/dialog'
import { LogDetalhesComponent } from './log-detalhes/log-detalhes.component'

@Component({
  selector: 'app-consulta-log',
  templateUrl: './consulta-log.component.html',
  styleUrls: ['./consulta-log.component.css'],
})
export class ConsultaLogComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  lastPage: number = 0
  lastPageSize: number = 25
  totalElements: number = 0

  tipoAcaoOperacao = LogAcoes
  tipoAcaoRefTo = LogRegistros

  nivelLogFC: FormControl = new FormControl('')
  dataInicialFC: FormControl = new FormControl()
  dataFinalFC: FormControl = new FormControl()
  dataMaxima: Date = new Date()
  usuarioFC: FormControl = new FormControl('')
  tipoAcaoOperacaoFC: FormControl = new FormControl('')
  tipoAcaoRefToFC: FormControl = new FormControl('')

  registro: number | null = null
  ipAddr: string | null = null
  msgSelecionado: string = ''

  colunasGrid: string[] = ['nivel', 'data', 'ip', 'username', 'acao', 'botao']
  dataSource: MatTableDataSource<LogEntry> = new MatTableDataSource()

  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private consultaLogService: ConsultaLogService,
    private unespCoreMessageService: UnespCoreMessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.nivelLogFC.setValue('TODOS')
    this.tipoAcaoOperacaoFC.setValue('TODOS')
    this.tipoAcaoRefToFC.setValue('TODOS')

    this.lastPage = this.paginator.pageIndex
    this.lastPageSize = this.paginator.pageSize

    this.paginator?.page
      .pipe(
        tap(() => {
          if (this.paginator.pageIndex != this.lastPage || this.paginator.pageSize != this.lastPageSize) {
            this.lastPage = this.paginator.pageIndex
            this.lastPageSize = this.paginator.pageSize
            this.consultar()
          }
        })
      )
      .subscribe()

    this.consultar()
  }

  private filtros() {
    let usuario: string | null = this.usuarioFC.value as string

    if (usuario && usuario.trim()) usuario = usuario.trim()
    else usuario = null

    return {
      nivel: this.nivelLogFC.value == 'TODOS' ? null : this.nivelLogFC.value,
      dataInicio: this.dataInicialFC.value ? this.dataInicialFC.value : null,
      dataFinal: this.dataFinalFC.value ? this.dataFinalFC.value : null,
      usuario,
      acaoOp: this.tipoAcaoOperacaoFC.value == 'TODOS' ? '' : (this.tipoAcaoOperacaoFC.value as string),
      acaoReg: this.tipoAcaoRefToFC.value == 'TODOS' ? null : (this.tipoAcaoRefToFC.value as string),
      registro: this.registro,
      ip: this.ipAddr,
    }
  }

  private consultar() {
    this.consultaLogService
      .listar(this.paginator.pageIndex, this.paginator.pageSize, this.filtros())
      .subscribe(page => {
        let logs: LogEntry[] = page['content']
        logs.forEach(log => {
          if (log.detalhesJSON) {
            log.detalhes = JSON.parse(log.detalhesJSON)
            log.temMaisDetalhes = true
          }
          geraTextoAcao(log)
        })
        this.dataSource = new MatTableDataSource(logs)
        this.totalElements = page['totalElements']
        this.paginator._changePageSize(page['pageable']['pageSize'])
      })
  }

  consultarBtnClick() {
    this.ipAddr = null
    this.registro = null
    this.msgSelecionado = ''
    this.consultar()
  }

  exportar() {
    /*
    let recursos: Concurso[] = this.dataSource.data
    if (recursos.length == 0) {
      this.unespCoreMessageService.showMessageError("Nenhum registro disponível.")
      return
    }
    let content = `"#","INSCRIÇÃO-INÍCIO","INSCRIÇÃO-TERMINO","UNIDADE","CONCURSO","PROCESSO","NÚMERO-CONTAD","TIPO","ANDAMENTO"\r\n`
    recursos.forEach(row => {
      content += `"${row.id}","${this.datepipe.transform(row.inicio, 'dd/MM/YYYY HH:mm:ss')}","${this.datepipe.transform(row.fim.toString() + ' ' + row.horaFim.toString(), 'dd/MM/YYYY HH:mm:ss')}","${row.campus}","${row.titulo}","${row.processo == null ? '' : row.processo}","${row.numeroContad == null ? '' : row.numeroContad}","${this.tipoConcursoTexto(row.tipo)}","${row.andamento}"\r\n`
    })
    let binaryData = []
    binaryData.push(content)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', 'andamentos.csv')
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: "attachment/csv;charset=utf-8;"}))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()*/
  }

  private limparFiltros() {
    this.nivelLogFC.setValue('TODOS')
    this.usuarioFC.setValue('')
    this.tipoAcaoOperacaoFC.setValue('TODOS')
    this.tipoAcaoRefToFC.setValue('TODOS')
    this.registro = null
    this.ipAddr = null
    this.msgSelecionado = ''
  }

  queryIP(ip: string) {
    this.limparFiltros()

    this.msgSelecionado = `Ações originadas do IP ${ip}`
    this.ipAddr = ip
    this.consultar()
  }

  queryUser(usr: string) {
    this.limparFiltros()

    this.usuarioFC.setValue(usr)
    this.consultar()
  }

  queryRegistro(log: LogEntry) {
    this.limparFiltros()

    const tipoReg = getAcaoTipoRegistro(log.acao)
    let linkPara = LogRegistrosMap.get(tipoReg)?.linkPara

    if (linkPara) this.tipoAcaoRefToFC.setValue(linkPara)
    else this.tipoAcaoRefToFC.setValue(tipoReg)

    this.registro = log.registroId ? log.registroId : null
    this.msgSelecionado = `Logs: ${LogRegistrosMap.get(tipoReg)?.nome} #${this.registro}`
    this.consultar()
  }

  queryRegistroPai(log: LogEntry) {
    this.limparFiltros()

    const tipoRegPai = getAcaoTipoPai(log.acao)
    this.tipoAcaoRefToFC.setValue(tipoRegPai)

    const tipoReg = getAcaoTipoRegistro(log.acao)
    let calcIdPai = LogRegistrosMap.get(tipoReg)?.calcIdPai

    if (!log.registroPai) this.registro = null
    else if (calcIdPai) this.registro = calcIdPai(log.registroPai)
    else this.registro = log.registroPai

    this.msgSelecionado = `Logs: ${LogRegistrosMap.get(tipoRegPai)?.nome} #${this.registro}`
    this.consultar()
  }

  abrirDetalhes(log: LogEntry) {
    const dialogRef = this.dialog.open(LogDetalhesComponent, { data: log, width: '90%' })
  }

  clearDate(): void {
    this.dataInicialFC.setValue(null)
    this.dataFinalFC.setValue(null)
  }
}
