/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UnespCoreLoaderService } from '../../services';


/**
 * @description
 *
 * Este *interceptor* tem a finalidade de inserir um loader enquanto a resposta de uma API está sendo aguardada.
 *
 * Ao importar o módulo `UnespCoreModule` na aplicação, o *interceptor* é automaticamente configurado sem a necessidade
 * de qualquer configuração extra.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class UnespCoreLoaderRequestInterceptorService implements HttpInterceptor {

  // Para dar suporte a várias chamadas
  serviceCount = 0;

  constructor(private unespCoreLoaderService: UnespCoreLoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.unespCoreLoaderService.isLoading.next(true);
    this.serviceCount++;

    return next.handle(req).pipe(
      finalize(
        () => {
          this.serviceCount--;

          if (this.serviceCount === 0) {
            this.unespCoreLoaderService.isLoading.next(false);
          }
        }
      )
    );
  }
}
