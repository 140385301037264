/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnespCoreSidenavComponent } from './unesp-core-sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { UnespCoreSidenavItemModule } from '../unesp-core-sidenav-item';
import { FlexLayoutModule } from "@ngbracket/ngx-layout";

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    UnespCoreSidenavItemModule,
    FlexLayoutModule
  ],
  declarations: [
    UnespCoreSidenavComponent
  ],
  exports: [
    UnespCoreSidenavComponent
  ]
})
export class UnespCoreSidenavModule { }
