<div *ngIf="(userLogin$ | async) === null" class="main-div">
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutAlign="center">
    <mat-card appearance="outlined" class="dashboard-card dashboard-card-user">
      <mat-card-header>
        <mat-card-title>Sample login</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" fxLayout="column" fxLayoutGap="5px" fxFill>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" maxlength="255" [email]="true" />
          </mat-form-field>
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>Senha</mat-label>
            <input matInput formControlName="senha" maxlength="255" type="password" autocomplete="off" />
          </mat-form-field>
          <button class="mt20" mat-raised-button color="primary" (click)="login()"><mat-icon>vpn_key</mat-icon> Entrar</button>
          <a class="mt20" mat-button (click)="recuperarSenha()">Esqueceu sua senha?</a>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="userLogin$ | async" fxLayout="column">
  <unesp-core-home style="display: none"></unesp-core-home>
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutAlign="center">
    <mat-card appearance="outlined" class="card">
      <mat-card-header>
        <mat-card-title> Olá, {{ user?.nomeUsuario }} </mat-card-title>
        <mat-card-subtitle>Utilize o menu para acessar as funcionalidades</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <hr />
        <table>
          <tr>
            <td>Perfil:</td>
            <td>{{ perfil }}</td>
          </tr>
          <tr *ngIf="user?.campus">
            <td>Unidade:</td>
            <td>
              {{ user?.campus }}<span *ngIf="user?.sigla"> - {{ user?.sigla }}</span>
            </td>
          </tr>
          <tr *ngIf="user?.nomeLotacaoOficial">
            <td>Lotação:</td>
            <td>{{ user?.nomeLotacaoOficial }}</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
