/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import {Component, ElementRef, Inject, OnInit, ViewChild,} from '@angular/core';
import {CountdownComponent, CountdownConfig, CountdownEvent,} from 'ngx-countdown';
import {UnespCoreEnvironment} from '../../interfaces';
import {UnespCoreAuthService, UnespCoreMessageService, UnespCoreUserService} from '../../services';

@Component({
  selector: 'unesp-core-header-countdown',
  templateUrl: './unesp-core-header-countdown.component.html',
  styleUrls: ['./unesp-core-header-countdown.component.css'],
})
export class UnespCoreHeaderCountdownComponent implements OnInit {

  @ViewChild('timer')
  timer!: CountdownComponent;

  @ViewChild('countdowndiv')
  countdowndiv!: ElementRef<HTMLDivElement>;

  @ViewChild('countdowndiv_expiredmsg')
  countdowndiv_expiredmsg!: ElementRef<HTMLDivElement>;

  @ViewChild('dialogwarning')
  dialogwarning!: ElementRef<HTMLDivElement>;

  @ViewChild('dialogwarningtext_almost')
  dialogwarning_almost!: ElementRef<HTMLDivElement>;

  @ViewChild('dialogwarningtext_lost')
  dialogwarning_lost!: ElementRef<HTMLDivElement>;

  config: CountdownConfig = {
    leftTime: 0,
    notify: 0,
    format: 'm:ss',
  };

  private warningStickyTime = 10000;
  warningNotifications?: number[] = undefined;
  lastWarningNotification?: number = undefined;
  timerKey: string = 'time';

  constructor(
    private unespCoreAuthService: UnespCoreAuthService,
    private unespCoreUserService: UnespCoreUserService,
    private unespCoreMessageService: UnespCoreMessageService,
    @Inject('environment') protected environment: UnespCoreEnvironment
  ) {
    if(!environment.refreshTokenExpirationInSeconds || !this.unespCoreAuthService.refreshTokenExpirationInSeconds) {
      throw new Error('environment.refreshTokenExpirationInSeconds precisa estar definido!');
    }
    this.warningNotifications = environment.refreshTokenExpirationActivityNotificationsTime;
    this.lastWarningNotification = environment.refreshTokenExpirationLastWarningTime;
    this.timerKey = this.unespCoreUserService.getLocalStoragePrefix() + '_time';
  }

  ngOnInit(): void {
    let value = 0;
    // Atualiza o timer de acordo com o tokendate
    if(localStorage.getItem(this.unespCoreAuthService.tokenDateKey)) {
      const tokenDateMillis = +localStorage.getItem(this.unespCoreAuthService.tokenDateKey)!;
      const nowMillings = Math.round(Date.now());
      const secondsElapsed = Math.floor((nowMillings - tokenDateMillis) / 1000);
      const secondsLeft = this.unespCoreAuthService.refreshTokenExpirationInSeconds! - secondsElapsed;
      value = secondsLeft;
    } else {
      // Atualiza o timer de acordo com o localstorage ou authService
      value = +localStorage.getItem(this.timerKey)!! ?? this.unespCoreAuthService.refreshTokenExpirationInSeconds;
    }
    value--; // init offset, for loading/reloading time
    if (value <= 0) {
      // value = this.unespCoreAuthService.refreshTokenExpirationInSeconds || 0;
      value = 0;
    }

    // atualiza a config do componente.
    this.config = { ...this.config, leftTime: value };

    this.unespCoreAuthService.aquiredTokenTimeLeft$.subscribe((timeLeft) => {
      if (timeLeft) {
        localStorage.setItem(this.timerKey, `${timeLeft}`);
        this.config = { ...this.config, leftTime: timeLeft };
        this.startTimer();
      }
    });
  }

  startTimer() {
    if (this.timer) {
      if (this.timer.left > 0) {
        this.timer.restart();
      } else {
        this.timer.begin();
      }
    }
  }

  handleEvent(e: CountdownEvent) {
    let secondsLeft = e.left / 1000;
    if (e.action === 'notify') {
      // Save current value
      localStorage.setItem(this.timerKey, `${secondsLeft}`);

      if (this.warningNotifications) {
        if (this.warningNotifications.includes(secondsLeft)) {
          this.showWarning();
        }
      }

      if (this.lastWarningNotification) {
        if (this.lastWarningNotification == secondsLeft) {
          this.showLastWarning(secondsLeft);
        }
      }
    }

    if (secondsLeft === 3)
      this.showDialogWarning_lost();

    if(e.action === 'done') {
      this.showDialogWarning_lost();
      this.changeCounterToExpired();
    }
}

  showWarning() {
    this.unespCoreMessageService.showMessageWarning(
      `Olá, você está aí? Lembre-se de salvar seus dados!`,
      undefined,
      this.warningStickyTime
    );
  }

  showLastWarning(timeLeft: number) {
    this.showDialogWarning_almost();

    let strTime = '';
    if (timeLeft < 60) strTime = ' em ' + timeLeft.toString() + ' segundos';
    else {
      let minutes = Math.floor(timeLeft / 60);
      strTime = ' em ' + minutes.toString() + ' minuto';
      if (minutes > 1) strTime += 's';
    }

    this.unespCoreMessageService.showMessageError(
      `Sua autorização para utilizar o sistema irá expirar${strTime}.`,
      undefined,
      this.warningStickyTime * 2
    );
  }

  showDialogWarning_almost() {
    this.dialogwarning.nativeElement.setAttribute('style', 'visibility: visible;');
    this.dialogwarning_almost.nativeElement.setAttribute('style', 'display: block;');
    this.dialogwarning_lost.nativeElement.setAttribute('style', 'display: none;');
    this.countdowndiv.nativeElement.setAttribute('style','color: red; position:absolute; z-index: 999999; font-size: xx-large; top: 5px; right: 5px;');
    this.countdowndiv_expiredmsg.nativeElement.setAttribute('style','display: none;');
  }

  showDialogWarning_lost() {
    this.dialogwarning.nativeElement.setAttribute('style','visibility: visible;');
    this.dialogwarning_almost.nativeElement.setAttribute('style','display: none;');
    this.dialogwarning_lost.nativeElement.setAttribute('style','display: block;');
    this.countdowndiv.nativeElement.setAttribute('style','color: red; position:absolute; z-index: 999999; font-size: xx-large; top: 5px; right: 5px;');
  }

  changeCounterToExpired() {
    this.countdowndiv_expiredmsg.nativeElement.setAttribute('style','display: block;');
  }

  hideDialogWarning() {
    this.dialogwarning.nativeElement.setAttribute('style','visibility: hidden;');
    this.dialogwarning_almost.nativeElement.setAttribute('style','display: none;');
    this.dialogwarning_lost.nativeElement.setAttribute('style','display: none;');
    this.countdowndiv.nativeElement.setAttribute('style', '');
  }
}
