/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { ActivatedRoute } from '@angular/router'
import { UnespCoreEnvironment, UnespCoreMessageService } from 'src/libs/unesp-core'
import { fromEvent, debounceTime, distinctUntilChanged, tap, forkJoin } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { DatePipe } from '@angular/common'
import { LocalStorageService } from 'src/app/services/local-storage.service'
import { StatusDocumentacaoService } from 'src/app/services/status-documentacao.service'
import { StatusDocumentacao } from 'src/app/models/status-documentacao'
import { DownloadService } from 'src/app/services/download.service'
import { MatDialog } from '@angular/material/dialog'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { ErrorHandlerService } from 'src/app/services/error-handler.service'

@Component({
  selector: 'app-status-documentacao',
  templateUrl: './status-documentacao.component.html',
  styleUrls: ['./status-documentacao.component.css'],
})
export class StatusDocumentacaoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef

  baseUrl: string
  totalElements: number = 0
  mostrarColuna: boolean = false
  colunasGrid: string[] = ['inscricao', 'candidato', 'contato', 'statusFase1', 'pago']
  dataSource: MatTableDataSource<StatusDocumentacao> = new MatTableDataSource()

  idConcurso: number = 0
  campus: string = ''
  tituloConcurso: string = ''
  filename: string = ''
  isDepartamento: boolean = false
  filtroPago: string = ''
  tipoConcurso!: TipoConcurso

  situacaoFase1: string = ''
  situacaoFase2: string = ''
  opcionalPendente: boolean = false
  opcionalPendenteSegundaFase: boolean = false

  datepipe: DatePipe = new DatePipe('pt-BR')
  identificacao: any
  candidato: any = []

  situacaoInscricao = {}

  constructor(
    private errorHandlerService: ErrorHandlerService,
    private statusDocumentacaoService: StatusDocumentacaoService,
    private route: ActivatedRoute,
    private unespCoreMessageService: UnespCoreMessageService,
    private localStorageService: LocalStorageService,
    private downloadService: DownloadService,
    @Inject('environment') env: UnespCoreEnvironment,
    private dialog: MatDialog
  ) {
    this.baseUrl = env.baseUrlApi
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['id']
      this.dataSource.sort = this.sort
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()

    setTimeout(() => {
      this.input.nativeElement.value = this.localStorageService.get('status-documentacao.search') ?? ''
      this.getData()
    }, 0)
  }

  public getData() {
    this.localStorageService.set('status-documentacao.search', this.input?.nativeElement.value)
    this.statusDocumentacaoService
      .listar(
        this.idConcurso,
        this.input?.nativeElement.value,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filtroPago
      )
      .subscribe(data => {
        let inscricoes = data['content'] as StatusDocumentacao[]

        this.dataSource = new MatTableDataSource(
          inscricoes.map(value => {
            this.campus = value.campus
            this.tituloConcurso = value.tituloConcurso
            this.tipoConcurso = value.tipoConcurso

            if ((this.mostrarColuna = this.mostraColunaSegundaFase(this.tipoConcurso))) {
              this.colunasGrid = ['inscricao', 'candidato', 'contato', 'statusFase1', 'statusFase2', 'pago']
            }
            this.buscaSituacaoDocumentacao(value.idInscricao).subscribe(objAusentes => {
              if (objAusentes) {
                if (this.mostraColunaSegundaFase(this.tipoConcurso)) {
                  value.situacaoFase2 = objAusentes.situacaoFase2
                  value.totalOpcionalEnviadoFase2 = objAusentes.totalOpcionalEnviadoFase2
                  value.totalObrigatorioPendenteSegundaFase = objAusentes.totalObrigatorioPendenteSegundaFase
                }
                value.totalObrigatorioPendente = objAusentes.totalObrigatorioPendente
                value.totalOpcionalNaoEnviado = objAusentes.totalOpcionalNaoEnviado
                value.totalOpcionalNaoEnviadoFase2 = objAusentes.totalOpcionalNaoEnviadoFase2
                value.totalOpcionalEnviado = objAusentes.totalOpcionalEnviado
                value.quantidadeMinimaOpcionalRequerido = objAusentes.quantidadeMinimaOpcionalRequerido

                value.periodoInscricao = objAusentes.periodoInscricao
                value.periodoSegundaFase = objAusentes.periodoSegundaFase
                value.hasComplementoInscricao = objAusentes.hasComplementoInscricao
                value.hasComplementoSegundaFase = objAusentes.hasComplementoSegundaFase
                value.situacaoFase1 = objAusentes.situacaoFase1

                this.dataSource.sortingDataAccessor = (item: any, property) => {
                  switch (property) {
                    case 'inscricao':
                      return item.idInscricao
                    case 'candidato':
                      return item.nome
                    case 'contato':
                      return item.email
                    case 'statusFase1':
                      return item.situacaoFase1
                    case 'statusFase2':
                      return item.situacaoFase2
                    case 'pago':
                      return item.pagoConcluida
                  }
                }
              }
            })
            return value
          })
        )
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
  }

  verificaQtdRegistrosParaExportar() {
    if (this.totalElements !== 0) {
      this.exportar()
    } else {
      this.unespCoreMessageService.showMessageError(`Não Existem registros para exportar`)
    }
  }

  exportar() {
    // let conteudo = ''
    this.statusDocumentacaoService
      .listar(
        this.idConcurso,
        this.input?.nativeElement.value,
        this.paginator.pageIndex,
        this.paginator.pageSize,
        this.filtroPago
      )
      .subscribe(data => {
        let recursos: StatusDocumentacao[] = data['content']
        let situacaoInscricoes$ = recursos.map(value => {
          this.campus = value.campus
          this.tituloConcurso = value.tituloConcurso
          this.tipoConcurso = value.tipoConcurso

          return this.buscaSituacaoDocumentacao(value.idInscricao).pipe(
            map(objAusentes => {
              if (objAusentes) {
                if (this.mostraColunaSegundaFase(this.tipoConcurso)) {
                  return (
                    `"${value.idInscricao ? value.idInscricao : ''}", "${value.idConcurso ? value.idConcurso : ''}",` +
                    `"${value.campus ? value.campus : ''}", "${value.tituloConcurso ? value.tituloConcurso : ''}",` +
                    `"${value.nome ? value.nome : ''}", "${value.cpf ? value.cpf : ''}",` +
                    `"${value.rg ? value.rg : ''}", "${value.email ? value.email : ''}",` +
                    `"${value.celular ? value.celular : ''}", "${value.telefone ? value.telefone : ''}",` +
                    `"${objAusentes.totalObrigatorioPendente ? objAusentes.totalObrigatorioPendente : ''}",` +
                    `"${objAusentes.totalOpcionalNaoEnviado ? objAusentes.totalOpcionalNaoEnviado : ''}",` +
                    `"${objAusentes.situacaoFase1 ? objAusentes.situacaoFase1 : ''}",` +
                    `"${
                      objAusentes.totalObrigatorioPendenteSegundaFase
                        ? objAusentes.totalObrigatorioPendenteSegundaFase
                        : ''
                    }",` +
                    `"${objAusentes.totalOpcionalNaoEnviadoFase2 ? objAusentes.totalOpcionalNaoEnviadoFase2 : ''}" ,` +
                    `"${objAusentes.situacaoFase2 ? objAusentes.situacaoFase2 : ''}",` +
                    `"${value.pago ? 'Sim' : 'Aguardando Pagamento'}",` +
                    `"${value.pagoUsuario ? value.pagoUsuario : ''}",` +
                    `"${value.pagoData ? this.datepipe.transform(value.pagoData, 'dd/MM/YYYY HH:mm:ss') : ''}",` +
                    `"${value.concluida ? 'Sim' : 'Não'}"\r\n`
                  )
                } else {
                  return (
                    `"${value.idInscricao ? value.idInscricao : ''}","${value.idConcurso ? value.idConcurso : ''}",` +
                    `"${value.campus ? value.campus : ''}", "${value.tituloConcurso ? value.tituloConcurso : ''}",` +
                    `"${value.nome ? value.nome : ''}","${value.cpf ? value.cpf : ''}",` +
                    `"${value.rg ? value.rg : ''}", "${value.email ? value.email : ''}",` +
                    `"${value.celular ? value.celular : ''}", "${value.telefone ? value.telefone : ''}",` +
                    `"${objAusentes.totalObrigatorioPendente ? objAusentes.totalObrigatorioPendente : ''}",` +
                    `"${objAusentes.totalOpcionalNaoEnviado ? objAusentes.totalOpcionalNaoEnviado : ''}",` +
                    `"${objAusentes.situacaoFase1 ? objAusentes.situacaoFase1 : ''}",` +
                    `"${value.pago ? 'Sim' : 'Aguardando Pagamento'}",` +
                    `"${value.pagoUsuario ? value.pagoUsuario : ''}",` +
                    `"${value.pagoData ? this.datepipe.transform(value.pagoData, 'dd/MM/YYYY HH:mm:ss') : ''}",` +
                    `"${value.concluida ? 'Sim' : 'Não'}"\r\n`
                  )
                }
              }
              return
            })
          )
        })
        forkJoin(situacaoInscricoes$).subscribe(retorno => {
          let conteudo = ''
          if (this.mostraColunaSegundaFase(this.tipoConcurso)) {
            conteudo =
              `"INSCRIÇÃO","CONCURSO", "UNIDADE","TÍTULO","NOME","CPF","RG","EMAIL",` +
              `"CELULAR","TELEFONE","OBRIGATÓRIOS PENDENTES 1ª FASE",` +
              `"OPCIONAIS PENDENTES 1 FASE", "SITUAÇÃO FASE 1",` +
              `"OBRIGATÓRIOS PENDENTES 2ª FASE", "OPCIONAIS PENDENTES 1 FASE",` +
              `"SITUAÇÃO FASE 1", "PAGO", "AUTOR", "DATA", "CONCLUÍDO"\r\n`
          } else {
            conteudo =
              `"INSCRIÇÃO","CONCURSO", "UNIDADE","TÍTULO","NOME","CPF","RG","EMAIL",` +
              `"CELULAR","TELEFONE","OBRIGATÓRIOS PENDENTES 1ª FASE",` +
              `"OPCIONAIS PENDENTES 1 FASE", "SITUAÇÃO FASE 1",` +
              `"PAGO", "AUTOR", "DATA", "CONCLUÍDO"\r\n`
          }

          conteudo += retorno.concat()
          let binaryData = []
          binaryData.push(conteudo)
          let downloadLink = document.createElement('a')
          downloadLink.setAttribute('download', 'concursos.csv')
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: 'attachment/csv;charset=utf-8;' })
          )
          document.body.appendChild(downloadLink)
          downloadLink.click()
          downloadLink.remove()
        })
      })
  }

  buscaSituacaoDocumentacao(idInscricao: number) {
    return this.statusDocumentacaoService.getStatusDocumentacao(idInscricao)
  }

  downloadAnexo() {
    this.downloadService.edital(this.idConcurso ?? 0)
  }

  mostraColunaSegundaFase(tipoConcurso: TipoConcurso): boolean {
    return ['RDIDP', 'RTP', 'RTC'].includes(tipoConcurso)
  }
}
