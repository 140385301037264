/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnespCorePageModel } from '../../interfaces';
import { UnespCoreEnvironment } from '../../interfaces';

/**
 * @description
 *
 * Serviço responsável por disponibilizar de maneira genérica o acesso a APIs paginadas ou não, métodos CRUD como
 * create(POST), update(PUT), delete(DELETE).
 * É possível extender este serviço e reaproveitar seus métodos para uso específico.
 *
 */
@Injectable({
  providedIn: 'root',
})
export class UnespCoreGenericService {
  ENDPOINT: string;

  constructor(
    private http: HttpClient,
    @Inject('environment') environment: UnespCoreEnvironment
  ) {
    this.ENDPOINT = environment.baseUrlApi;
  }

  /** Retorna Lista Paginada de dados <T> */
  fetchPagination<returnType>(
    route: string,
    p?: HttpParams
  ): Observable<UnespCorePageModel<returnType>> {
    return this.http
      .get<UnespCorePageModel<returnType>>(`${this.ENDPOINT}/${route}`, { params: p })
      .pipe(map((data) => data));
  }

  /** Retorna uma Lista de Dados <T> sem paginação */
  fetchWithoutPagination<returnType>(
    route: string,
    p?: HttpParams
  ): Observable<returnType[]> {
    return this.http
      .get<returnType[]>(`${this.ENDPOINT}/${route}`, { params: p })
      .pipe(map((data) => data));
  }

  /** Retorna Item unico de <T> */
  findById<returnType>(
    route: string,
    id: number | string
  ): Observable<returnType> {
    return this.http
      .get<returnType>(`${this.ENDPOINT}/${route}/${id}`)
      .pipe(map((data) => data));
  }

  /** Retorna Item de <T> */
  get<returnType>(
    route: string
  ): Observable<returnType> {
    return this.http
      .get<returnType>(`${this.ENDPOINT}/${route}`)
      .pipe(map((data) => data));
  }

  /** Adiciona recurso */
  create<returnType>(route: string, input: returnType): Observable<returnType> {
    return this.http
      .post<returnType>(`${this.ENDPOINT}/${route}`, input)
      .pipe(map((obj) => obj));
  }

  /** Atualiza recurso */
  update<returnType>(
    uri: string,
    input: returnType
  ): Observable<returnType> {
    const url = `${this.ENDPOINT}/${uri}`;
    return this.http.put<returnType>(url, input).pipe(map((obj) => obj));
  }

  /** Remove recurso */
  delete<returnType>(
    route: string,
    id: number | string
  ): Observable<returnType> {
    const url = `${this.ENDPOINT}/${route}/${id}`;
    return this.http.delete<returnType>(url).pipe(map((obj) => obj));
  }
}
