<div fxFlexLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
  <div fxFlex #countdowndiv_expiredmsg class="unesp-header-countdown-expiredmsg" style="display: none;">
    Sessão expirada!
  </div>
  <div fxFlex #countdowndiv class="unesp-header-countdown">
    <countdown #timer [config]="config" (event)="handleEvent($event)"></countdown>
  </div>
</div>
<div #dialogwarning class="unesp-header-countdown-dialog-lastwarning" style="visibility: hidden;">
  <div class="unesp-header-countdown-dialog-lastwarning-content">
    <div class="unesp-header-countdown-dialog-lastwarning-content-title">
      Atenção
    </div>
    <div #dialogwarningtext_almost class="unesp-header-countdown-dialog-lastwarning-content-text" style="visibility: hidden;">
      <p>
        Você está prestes a perder sua autorização para continuar utilizando o sistema.<br/>
        Caso tenha dados pendentes no sistema, salve-os imediatamente.<br/>
      </p>
    </div>
    <div #dialogwarningtext_lost class="unesp-header-countdown-dialog-lastwarning-content-text" style="visibility: hidden;">
      <p>
        Você perdeu sua autorização para continuar utilizando o sistema.<br/>
        Caso tenha dados pendentes no sistema, salve-os em um local separado antes de prosseguir no sistema.<br/>
      </p>
    </div>
    <button mat-raised-button color="primary" (click)="hideDialogWarning()">
      Fechar este aviso
    </button>
  </div>
</div>