<div *ngIf="(userLogin$ | async) === null" class="main-div">
  <unesp-core-home></unesp-core-home>
</div>

<div *ngIf="userLogin$ | async" fxLayout="column">
  <unesp-core-home style="display: none"></unesp-core-home>
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutAlign="center">
    <mat-card class="dashboard-card dashboard-card-user">
      <mat-card-header>
        <mat-card-title> Olá, {{ userLogin?.nomeUsuario }} </mat-card-title>
        <mat-card-subtitle>Utilize o menu para acessar as funcionalidades</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <hr />
        <table>
          <tr>
            <td>Perfil:</td>
            <td>{{ perfil }}</td>
          </tr>
          <tr *ngIf="userLogin?.campus">
            <td>Unidade:</td>
            <td>
              {{ userLogin?.campus }}<span *ngIf="userLogin?.sigla"> - {{ userLogin?.sigla }}</span>
            </td>
          </tr>
          <tr *ngIf="userLogin?.nomeLotacaoOficial">
            <td>Lotação:</td>
            <td>{{ userLogin?.nomeLotacaoOficial }}</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
