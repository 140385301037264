<div *ngIf="!(user$ | async)" class="main-div">
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>Bem vindo(a)</mat-card-title>
      <mat-card-subtitle class="card-subtitle">Efetue login para acessar o sistema</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions class="card-button">
      <button mat-raised-button color="primary" (click)="login()">Entrar usando Central de Acessos</button>
    </mat-card-actions>
  </mat-card>
</div>

<div *ngIf="user$ | async" class="main-div">
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>Olá{{nomeUsuario}}</mat-card-title>
      <mat-card-subtitle class="card-subtitle">Utilize o menu para acessar as funcionalidades</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>
