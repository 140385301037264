/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { Campus } from '../models/campus'
import { Concurso } from '../models/concurso'
import { RegistroPermitirAlteracao } from '../models/registro-permitir-alteracao'
import { Andamento } from '../models/andamento'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class PermitirAlteracaoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  getUnidades(): Observable<Campus[]> {
    return this.http
      .get<Campus[]>(`${this.baseUrl}/permitir-alteracao/listar-unidades`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  getConcursos(unidade: number): Observable<Concurso[]> {
    return this.http
      .get<Concurso[]>(`${this.baseUrl}/permitir-alteracao/campus/${unidade}/listar-concursos`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listarRegistros(tipo: string, concurso: number): Observable<RegistroPermitirAlteracao[]> {
    return this.http
      .get<RegistroPermitirAlteracao[]>(`${this.baseUrl}/permitir-alteracao/concurso/${concurso}/listar-${tipo}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  liberarRegistro(tipo: string, id: number): Observable<RegistroPermitirAlteracao> {
    return this.http
      .put<RegistroPermitirAlteracao>(`${this.baseUrl}/permitir-alteracao/${tipo}/${id}/liberar`, null)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  getAndamento(id: number): Observable<Andamento> {
    return this.http
      .get<Andamento>(`${this.baseUrl}/permitir-alteracao/andamento/${id}/get`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  reverterProva(concursoId: number, tipoProva: string, novoPasso: string): Observable<RegistroPermitirAlteracao> {
    return this.http
      .put<RegistroPermitirAlteracao>(
        `${this.baseUrl}/permitir-alteracao/concurso-prova/${concursoId}/${tipoProva}/reverter-para/${novoPasso}`,
        null
      )
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
