/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { UnespCoreAuthService } from '../../services';

/**
 * @description
 *
 * Este *interceptor* tem a finalidade de injetar corretamente os tokens (JWT/XSRF) e tratar possíveis erros retornados pela API para estes tokens.
 *
 * Pode ser utilizado para dar feedback das ações do usuário como, por exemplo: erro de autorização, mensagens de regras de negócio
 * e erros de indisponibilidade do backend.
 *
 * Ao importar o módulo `UnespCoreModule` na aplicação, o *interceptor* é automaticamente configurado sem a necessidade
 * de qualquer configuração extra.
 *
 * Ao realizar requisições utilize o `HttpClient`, conforme exemplo abaixo:
 *
 *
 * ```
 * ...
 *
 * import { HttpClient } from '@angular/common/http';
 *
 *
 * @Injectable()
 * export class UserService {
 *
 *   constructor(private http: HttpClient) { }
 *
 *   getUsers() {
 *     return this.http.get('/api/users');
 *   }
 *
 *   ...
 *
 * }
 * ```
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UnespCoreAuthInterceptorService implements HttpInterceptor {

  private refreshingInProgress = false;
  private accessTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private headerXsrf = 'X-XSRF-TOKEN';

  constructor(
    private unespCoreAuthService: UnespCoreAuthService,
    private tokenExtractor: HttpXsrfTokenExtractor
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenExtractor.getToken() as string;

    if (token !== null && !request.headers.has(this.headerXsrf)) {
      request = request.clone(
        {
          headers: request.headers.set(this.headerXsrf, token),
          withCredentials: true
        }
      );
    } else {
      request = request.clone({
        withCredentials: true
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        // No caso de erro 401 Unauthorized
        if (err instanceof HttpErrorResponse && err.status === 401 && !request.url.includes('auth/logout')) {
          return this.refreshToken(request, next);
        }

        // No caso de erro 403 Forbidden (refresh token falhou)
        if (err instanceof HttpErrorResponse && err.status === 403) {
          if (request.url.includes('auth/refresh-token')){
            // logout and redirect to login page
            this.unespCoreAuthService.logout('Sessão expirada', true);
          } else {
            // logout and redirect to login page
            if (err.error && err.error[0]?.mensagemUsuario) {
              this.unespCoreAuthService.logout(err.error[0].mensagemUsuario, true);
            } else {
              this.unespCoreAuthService.logout('Sessão expirada', true);
            }
          }
        }

        // Retornar outros tipos de erros
        return throwError(err);
      })
    );
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(false);

      return this.unespCoreAuthService.refreshToken().pipe(
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(true);
          this.unespCoreAuthService.renewAquiredTokenTimeleft();
          // Repete a requisição com falha mas agora com novo token
          return next.handle(request);
        })
      );
    } else {
      // Espera enquanto obtem um novo token - Fila
      return this.accessTokenSubject.pipe(
        filter(token => token),
        take(1),
        switchMap(token => {
          // Repete a requisição com falha mas agora com novo token
          this.unespCoreAuthService.renewAquiredTokenTimeleft();
          return next.handle(request);
        })
      );
    }
  }
}
