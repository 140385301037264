/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { map, Observable, of, startWith } from 'rxjs'
import { TipoAndamento } from 'src/app/enums/tipo-andamento'
import { TipoConcurso } from 'src/app/enums/tipo-concurso'
import { Campus } from 'src/app/models/campus'
import { Concurso } from 'src/app/models/concurso'
import { ConsultaAndamentoService } from 'src/app/services/consulta-andamento.service'
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips'
import { FiltrosConcursoForm } from '../../models/filtros-concurso-form'
import { DatePipe } from '@angular/common'
import { UnespCoreMessageService } from 'src/libs/unesp-core'

@Component({
  selector: 'app-consulta-andamento',
  templateUrl: './consulta-andamento.component.html',
  styleUrls: ['./consulta-andamento.component.css'],
})
export class ConsultaAndamentoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('unidadesChipList') unidadesChipList: MatChipGrid | undefined
  @ViewChild('unidadeInput') unidadeInput: ElementRef | undefined

  tipoConcurso = TipoConcurso
  tipoAndamento = TipoAndamento

  todasUnidades: Campus = { nome: 'Todas Unidades', uuid: -1 }
  showTodasUnidadesMC: boolean = true
  listaDeUnidades: Campus[] = []
  unidadesSelecionadas: Campus[] = []
  unidadesFiltradas: Observable<Campus[]> = of(this.listaDeUnidades)

  unidadeFC: FormControl = new FormControl('')
  tipoConcursoFC: FormControl = new FormControl('')
  tipoAndamentoFC: FormControl = new FormControl('')
  dataInscricaoFC: FormControl = new FormControl()
  dataMaxima: Date = new Date()

  colunasGrid: string[] = ['id', 'inscricao', 'campus', 'titulo', 'processo', 'contad', 'tipo', 'andamento']
  dataSource: MatTableDataSource<Concurso> = new MatTableDataSource()

  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private consultaAndamentoService: ConsultaAndamentoService,
    private unespCoreMessageService: UnespCoreMessageService
  ) {}

  ngOnInit(): void {
    this.tipoConcursoFC.setValue('TODAS')
    this.tipoAndamentoFC.setValue('TODAS')

    this.consultaAndamentoService.getUnidades().subscribe(arr => {
      this.listaDeUnidades = arr
      this.listaDeUnidades.push(this.todasUnidades)
    })

    this.unidadesFiltradas = this.unidadeFC.valueChanges.pipe(
      startWith(''),
      map(value => {
        const nome = typeof value === 'string' ? value : this.addChip(value)
        return nome ? this._filtroUnidade(nome as string) : this.listaDeUnidades.slice()
      })
    )
  }

  focusChipIput(): void {
    this.showTodasUnidadesMC = false
  }

  blurChipIput(event: MatChipInputEvent): void {
    event.chipInput!.clear()
    this.showTodasUnidadesMC = true
  }

  addChip(unidade: Campus): void {
    if (unidade.uuid == -1) this.unidadesSelecionadas = []
    else if (this.unidadesSelecionadas.indexOf(unidade) == -1) this.unidadesSelecionadas.push(unidade)
  }

  removeChip(unidade: Campus): void {
    const index = this.unidadesSelecionadas.indexOf(unidade)
    if (index >= 0) this.unidadesSelecionadas.splice(index, 1)
    if (unidade.uuid == -1) this.unidadeInput?.nativeElement.focus()
  }

  private _filtroUnidade(nome: string) {
    const filterValue = nome.toLowerCase()
    return this.listaDeUnidades.filter(
      opt => this.unidadesSelecionadas.indexOf(opt) == -1 && opt.nome.toLowerCase().includes(filterValue)
    )
  }

  nomeUnidade(unidade: Campus): string {
    return unidade && unidade.nome ? unidade.nome : ''
  }

  tipoConcursoTexto(str?: string): string {
    if (typeof str === 'undefined' || str == null || str == '') return ''
    return (TipoConcurso as any)[str]
  }

  tipoAndamentoTexto(str?: string): string {
    if (typeof str === 'undefined' || str == null || str == '') return ''
    return (TipoAndamento as any)[str]
  }

  consultar() {
    let filtros: FiltrosConcursoForm = {
      unidades: this.unidadesSelecionadas.map(uni => {
        return uni.uuid
      }),
      tipo: this.tipoConcursoFC.value == 'TODAS' ? undefined : this.tipoConcursoFC.value,
      andamento: this.tipoAndamentoFC.value == 'TODAS' ? undefined : this.tipoAndamentoFC.value,
      data: this.dataInscricaoFC.value,
    }
    this.consultaAndamentoService.listar(filtros).subscribe(concursos => {
      this.dataSource = new MatTableDataSource(concursos)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator

      this.dataSource.sortingDataAccessor = (item: any, property) => {
        switch (property) {
          case 'inscricao':
            return item.inicio
          default:
            return item[property]
        }
      }
    })
  }

  exportar() {
    let recursos: Concurso[] = this.dataSource.data
    if (recursos.length == 0) {
      this.unespCoreMessageService.showMessageError('Nenhum registro disponível.')
      return
    }
    let content = `"#","INSCRIÇÃO-INÍCIO","INSCRIÇÃO-TERMINO","UNIDADE","CONCURSO","PROCESSO","NÚMERO-CONTAD","TIPO","ANDAMENTO","ANDAMENTO-DATA"\r\n`
    recursos.forEach(row => {
      content += `"${row.id}","${this.datepipe.transform(
        row.inicio,
        'dd/MM/YYYY HH:mm:ss'
      )}","${this.datepipe.transform(row.fim.toString() + ' ' + row.horaFim.toString(), 'dd/MM/YYYY HH:mm:ss')}","${
        row.campus
      }","${row.titulo}","${row.processo == null ? '' : row.processo}","${
        row.numeroContad == null ? '' : row.numeroContad
      }","${this.tipoConcursoTexto(row.tipo)}","${row.andamento}","${this.datepipe.transform(
        row.andamentoData,
        'dd/MM/YYYY'
      )}"\r\n`
    })
    let binaryData = []
    binaryData.push(content)
    let downloadLink = document.createElement('a')
    downloadLink.setAttribute('download', 'andamentos.csv')
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csv;charset=utf-8;' }))
    document.body.appendChild(downloadLink)
    downloadLink.click()
    downloadLink.remove()
  }

  clearDate(): void {
    this.dataInscricaoFC.setValue(null)
  }
}
