<form novalidate>
    <mat-card class="page-not-found">
        <mat-card-header style="flex-direction: column;">
            <mat-card-title >Página não encontrada!</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div>
                <img src="assets/img/page-not-found.png">
            </div>
            
            <div>
                <button type="button" mat-raised-button (click)="voltar()" color="primary">
                    <mat-icon>undo</mat-icon> Voltar
                </button>
            </div>
        </mat-card-content>
    </mat-card>    
</form>
