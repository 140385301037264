<div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlexFill>
  <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="space-between">
    <unicamp-core-header fullTitle="{{ fullTitle }}" shortTitle="{{ shortTitle }}" fxFlexFill></unicamp-core-header>
  </div>
  <div fxFlex="1 1 auto">
    <unesp-core-sidenav fxFlexFill [loginOnSidenav]="false">
      <div class="ngcontent-content" fxFlexFill>
        <div [@routeAnimations]="prepareRoute(outlet)" style="padding: 20px">
          <router-outlet #outlet="outlet"></router-outlet>
        </div>
      </div>
    </unesp-core-sidenav>
  </div>
  <div fxFlex="0 0 auto" fxLayout="row" id="unesp-core-footer">
    <unesp-core-footer fxFlexFill>
      <div class="ngcontent-select-footer">
        {{ footerText }}
      </div>
    </unesp-core-footer>
  </div>
</div>
