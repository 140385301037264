/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    sequence
} from '@angular/animations';

/**
 * Animação referente a navegação entre rotas
 */
const resetRoute = [
    style({ position: 'relative' }),
    query(
        ':enter, :leave',
        [
            style({
                position: 'absolute',
                width: '100%',
                opacity: 0,
            }),
        ],
        { optional: true }
    ),
];


/**
 * Animação referente a navegação entre rotas
 */
export const routeAnimations =
    trigger('routeAnimations', [
        transition('* => *', [
            ...resetRoute,
            query(':enter', [style({ opacity: 0 })], {
                optional: true,
            }),
            group([
                query(
                    ':leave',
                    [style({ opacity: 1 }), animate('0.250s', style({ opacity: 0 }))],
                    { optional: true }
                ),
                query(
                    ':enter',
                    [style({ opacity: 0 }), animate('0.250s', style({ opacity: 1 }))],
                    { optional: true }
                ),
            ]),
        ]),
    ]);


/**
 * Animação ao exibir lista de resultados em MatTable
 */
export const rowsMatTableAnimation =
    trigger('rowsMatTableAnimation', [
      transition('void => *', [
        style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
        sequence([
          animate('.35s ease', style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none' })),
          animate('.35s ease', style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
        ])
      ])
    ]);
