/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { ROLE_WILDCARD_PUBLIC, UnespCoreMenuItem } from 'src/libs/unesp-core'

export const getMenu = (logged: boolean, perfis: string[]): UnespCoreMenuItem[] => {
  const menu: UnespCoreMenuItem[] = [
    {
      displayName: 'Concursos',
      iconName: 'edit_calendar',
      roles: ['RH', 'DTA', 'CCPT', 'CACP', 'FINANCAS', 'DIRETOR', 'MULTIUNIDADES', 'DEPARTAMENTO'],
      order: 1,
      route: '/concurso',
    },
    {
      displayName: 'Inscrições',
      iconName: 'list_alt',
      roles: ['RH', 'DTA', 'FINANCAS', 'CCPT', 'CACP', 'DEPARTAMENTO', 'MULTIUNIDADES', 'DIRETOR'],
      order: 2,
      route: '/inscricao',
    },
    {
      displayName: 'Recursos',
      iconName: 'gavel',
      roles: ['RH', 'DEPARTAMENTO', 'DIRETOR'],
      order: 3,
      route: '/recurso',
    },
    // {
    //   displayName: 'Usuários externo',
    //   iconName: 'manage_accounts',
    //   roles: ['RH', 'DTA'],
    //   order: 4,
    //   route: '/usuario-externo',
    // },
    {
      displayName: 'Configurações',
      iconName: 'settings',
      roles: ['RH', 'FINANCAS'],
      order: 5,
      route: '/configuracao',
    },
    {
      displayName: 'Gerência',
      iconName: 'menu',
      roles: ['GERENTE'],
      order: 6,
      addTopDivider: true,
      addBottomDivider: true,
      children: [
        {
          displayName: 'Consulta gerencial',
          iconName: 'summarize',
          roles: ['GERENTE'],
          order: 1,
          route: '/consulta-gerencial',
        },
        {
          displayName: 'Consulta andamento',
          iconName: 'summarize',
          roles: ['GERENTE'],
          order: 2,
          route: '/consulta-andamento',
          addBottomDivider: false,
        },
        {
          displayName: 'Consulta logs',
          iconName: 'history',
          roles: ['GERENTE'],
          order: 3,
          route: '/consulta-log',
          addBottomDivider: true,
        },
        {
          displayName: 'Candidatos',
          iconName: 'people',
          roles: ['GERENTE'],
          order: 4,
          route: '/usuario-candidato',
        },
        {
          displayName: 'Configurações',
          iconName: 'settings',
          roles: ['GERENTE'],
          order: 5,
          route: '/gerencia-configuracao',
        },
        {
          displayName: 'Permitir alterações',
          iconName: 'lock_open',
          roles: ['GERENTE'],
          order: 6,
          route: '/permitir-alteracao',
        },
        {
          displayName: 'Formulário complementar',
          iconName: 'format_list_numbered',
          roles: ['GERENTE'],
          order: 6,
          route: '/formulario-complementar',
        },
        {
          displayName: 'Tipos de Recursos',
          iconName: 'file_copy',
          roles: ['GERENTE'],
          order: 7,
          route: '/tipo-recurso',
        },
        // {
        //   displayName: 'Editar Perfis',
        //   iconName: 'person_pin',
        //   roles: ['GERENTE'],
        //   order: 8,
        //   route: '/perfil',
        //   addBottomDivider: false,
        // },
      ],
    },
    {
      displayName: 'Manual STGP',
      iconName: 'picture_as_pdf',
      roles: ['RH'],
      order: 94,
      route: '/manual/stgp',
    },
    {
      displayName: 'Manual DTA',
      iconName: 'picture_as_pdf',
      roles: ['DTA'],
      order: 95,
      route: '/manual/dta',
    },
    {
      displayName: 'Manual Finanças',
      iconName: 'picture_as_pdf',
      roles: ['FINANCAS'],
      order: 96,
      route: '/manual/financas',
    },
    {
      displayName: 'Manual Banca',
      iconName: 'picture_as_pdf',
      roles: ['DEPARTAMENTO'],
      order: 97,
      route: '/manual/banca',
    },
    {
      displayName: 'Manual CCPT',
      iconName: 'picture_as_pdf',
      roles: ['CCPT'],
      order: 98,
      route: '/manual/ccpt',
    },
    {
      displayName: 'Manual CACP',
      iconName: 'picture_as_pdf',
      roles: ['CACP'],
      order: 99,
      route: '/manual/cacp',
    },
    // {
    //   displayName: 'Ajuda/Suporte',
    //   iconName: 'contact_support',
    //   roles: [ROLE_WILDCARD_PUBLIC],
    //   route: '/ajuda/suporte',
    //   order: 100,
    // },
    // {
    //   displayName: 'Voltar ao portal',
    //   iconName: 'home',
    //   roles: [ROLE_WILDCARD_PUBLIC],
    //   route: '/central-acesso/home',
    //   order: 101,
    // },
    {
      displayName: 'Login',
      iconName: 'login',
      roles: [ROLE_WILDCARD_PUBLIC],
      route: '',
      order: 1002,
      addTopDivider: false,
      hidden: !logged
    },
    {
      displayName: 'Sair',
      iconName: 'login',
      roles: [ROLE_WILDCARD_PUBLIC],
      route: 'logout',
      order: 1002,
      addTopDivider: true,
      hidden: logged
    },
  ]

  return menu
    .filter(item => !item.hidden)
    .filter(item => item.roles.some(role => {
      return perfis.includes(role) || role == '**'
    }))
}